function MeniSizeItem({ name, ingredients, price }) {
  return (
    <>
      <div className='size-single-item-container'>
        <div className='size-info'>
          <span className='size-name menu-item-primary-text'>{name}</span>
          <div className='separator'></div>
          <span className='burger-price menu-item-secondary-text'>{price}</span>
        </div>
        <div className='ingredients menu-item-secondary-text'>
          {ingredients}
        </div>
      </div>
    </>
  )
}
export default MeniSizeItem
