import ContactInfo from "./ContactInfo";

import ContactModal from "./ContactModal";

import { useState } from "react";
function Contact() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalHeading, setModalHeading] = useState("Obrađujemo zahtev");
  return (
    <>
      <section className="contact-section" id="kontakt">
        {showModal ? (
          <ContactModal
            setShowModal={setShowModal}
            modalHeading={modalHeading}
            modalText={modalText}
          ></ContactModal>
        ) : (
          ""
        )}
        <h1 id="contact-header">Kontakt</h1>

        <div className="contact-content">
          <div className="left-info-container">
            <h3 className="contact-heading"></h3>

            {/* Informacije o restoranu */}
            <div className="info-wrapper">
              {/* Pojedinacna informacija */}

              <ContactInfo
                key={1}
                imgPath={"/images/clock.webp"}
                Title={"Radno vreme"}
                text={[
                  "Ned - Čet : 10:00 - 00:00",
                  "Pet - Sub : 12:00 - 03:00",
                ]}
              ></ContactInfo>
              <ContactInfo
                key={2}
                imgPath={"/images/adresa.webp"}
                Title={"Adresa"}
                text={["Požeška 118a"]}
              ></ContactInfo>
              <ContactInfo
                key={3}
                imgPath={"/images/telephone.webp"}
                Title={"Kontakt telefon"}
                text={["069-333-6071"]}
              ></ContactInfo>
              <ContactInfo
                key={4}
                imgPath={"/images/rucak.webp"}
                Title={"Ručavanje u objektu"}
                text={[""]}
              ></ContactInfo>
              <ContactInfo
                key={5}
                imgPath={"/images/shipped.webp"}
                Title={"Dostava"}
                text={[""]}
              ></ContactInfo>
            </div>
          </div>

          {/* Kontakt forma desno */}
          <div className="right-contact-form-container">
            <h3 className="contact-heading">Pišite nam!</h3>

            <form action="">
              <div className="form-row">
                <label htmlFor="imeprez">Ime i prezime:*</label>
                <input type="text" id="imeprez" />
              </div>
              <div className="form-row">
                <label htmlFor="email">Email:*</label>
                <input type="text" id="email" />
              </div>
              <div className="form-row">
                <label htmlFor="naslov">Naslov:</label>
                <input type="text" id="naslov" />
              </div>
              <div className="form-row">
                <label htmlFor="poruka">Poruka:*</label>
                <textarea name="Text1" id="poruka" />
              </div>
              <button
                type="submit"
                onClick={async (e) => {
                  e.preventDefault();
                  setShowModal(true);
                  setModalText("");
                  setModalHeading("Obrađujemo zahtev");

                  try {
                    const webUrl =
                      "https://two-guys-burger-back.vercel.app/questions";
                    const localUrl = "http://localhost:5000/questions";
                    const res = await fetch(webUrl, {
                      method: "POST",
                      body: JSON.stringify({
                        title: "Naslov",
                        fullName: "Milan",
                        email: "miki@gmail",
                        message: "dadad",
                      }),
                      headers: {
                        "Content-Type": "application/json",
                      },
                    });

                    const data = await res.json();

                    console.log(data);
                    if (res.status.toString().startsWith("4")) {
                      throw new Error(
                        "Loš zahtev. Proverite podatke i pokušajte ponovo!"
                      );
                    }
                    if (res.status.toString().startsWith("5")) {
                      throw new Error(
                        "Greška sa serverom. Pokušajte malo kasnije!"
                      );
                    }

                    setModalText(
                      "Vaše pitanje je uspešno poslato. Očekujte odgovor uskoro!"
                    );
                    setModalHeading("Uspešno");
                    console.log(data);
                  } catch (e) {
                    setModalHeading("Neuspešno");
                    setModalText("Greška");
                  }
                }}
                className="submit-message-btn btn-classic"
              >
                Pošalji poruku
              </button>
            </form>
          </div>
        </div>

        <div className="map-container">
          <h3 className="contact-heading" id="map-heading">
            Potražite <strong>Two Guys</strong> na lokaciji:
          </h3>
          <h3 className="contact-heading adress-heading">
            Požeška 118a, Banovo brdo
          </h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d800.5224786268828!2d20.41316404599859!3d44.77643684574416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a6f64f50463b1%3A0x56e5050bf09e8093!2sTwo%20Guys%20Smash%20Burger!5e0!3m2!1ssr!2srs!4v1678529226792!5m2!1ssr!2srs"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="google mapa"
          ></iframe>
        </div>
      </section>
    </>
  );
}

export default Contact;
