import { useRef, memo, useEffect, React } from 'react'

function BurgerItem({
  id,
  name,
  ingredients,
  image,
  keyIngredient,
  keyIngredientImage,
  bestBuy,
  selected,
  SelectBurgerItem,
  isMobile,
}) {
  //izracunaj visinu accordion contenta
  const accordionContentRef = useRef(null)

  let height = accordionContentRef.current?.scrollHeight
  if (!selected) height = 0

  useEffect(() => {
    console.log('render burger item!')
  })
  return (
    <>
      <div
        className={`${selected ? 'selected ' : ''} single-burger-container`}
        onClick={() => {
          SelectBurgerItem(id)
        }}
      >
        <div className='shining-helper'></div>
        {/* Image of burger */}
        <div className='burger-img-wrapper'>
          <img
            src={`${image.substring(0, image.indexOf('.'))}-mini.png`}
            alt='slika burgera'
          />
        </div>
        <div className='burger-info'>
          <h3>{name}</h3>
        </div>
        <div className='accordion-arrow-wrapper'>
          <img src='images/strelica.webp' alt='strelica accordion' />
        </div>

        {/* best buy ribbon */}
        {bestBuy ? <span className='best-buy-ribbon'> </span> : ''}
      </div>
      {/* ZA MOBILNI, prikaz detaljniji */}
      {isMobile ? (
        <div
          ref={accordionContentRef}
          className={`${selected ? 'expanded' : ''}
           big-burger-accordion-container`}
          style={{ minHeight: height }}
        >
          <div className='burger-img-wrapper'>
            <img src={image} alt='slika burgera' />
          </div>
          <div className='burger-ingredients-container'>
            <h5>Sastojci:</h5>
            <p>{ingredients}</p>
            {/* 100% beef and other characteristic */}
            <div className='burger-characteristic'>
              <div className='single-burger-characteristic'>
                <div className='beef-img-wrapper'>
                  <img src='images/kravica.webp' alt='kravica' />
                </div>
                <p>100% govedina</p>
              </div>
              <div className='single-burger-characteristic'>
                <div className='beef-img-wrapper'>
                  <img src={keyIngredientImage} alt='karakteristika' />
                </div>
                <p>{keyIngredient}</p>
              </div>
            </div>
            {/* <h6>
              <span>156 dinara</span>
            </h6> */}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

function areEqual(prevProps, nextProps) {
  if (
    prevProps.id == nextProps.id &&
    prevProps.selected == nextProps.selected
  ) {
    return true
  }
  return false
}

export default memo(BurgerItem, areEqual)
