import MeniBurgerItem from "./MeniBurgerItem";
import MeniSizeItem from "./MeniSizeItem";

import { burgerTypeList } from "../Home/BurgerList";
import { useState, useRef } from "react";
const pomfritList = [
  {
    name: "Pomfrit",
    price: 250,
  },
  {
    name: "Čedar pomfrit",
    price: 320,
  },
  {
    name: "Čedar Bacon pomfrit",
    price: 350,
  },
  {
    name: "Two Guys pomfrit",
    price: 530,
  },
  {
    name: "Onion Rings",
    price: 330,
  },
  {
    name: "Mozzarella sticks",
    price: 420,
  },
];
const soseviList = [
  {
    name: "Kečap",
    price: 100,
  },
  {
    name: "Majonez",
    price: 100,
  },
  {
    name: "Burger sos",
    price: 110,
  },
  {
    name: "BBQ",
    price: 110,
  },
  {
    name: "Cezar",
    price: 120,
  },
  {
    name: "1000 ostrva",
    price: 120,
  },
];
const picaList = [
  {
    name: "Rosa",
    price: 150,
  },
  {
    name: "Gazirana rosa",
    price: 150,
  },
  {
    name: "Coca-Cola",
    price: 180,
  },
  {
    name: "Sprite",
    price: 180,
  },
  {
    name: "Fanta",
    price: 180,
  },
  {
    name: "Schweppes",
    price: 180,
  },
  {
    name: "Next lemonade",
    price: 180,
  },
  {
    name: "Fuze Tea breskva",
    price: 180,
  },
  {
    name: "Ultra Energy",
    price: 250,
  },
  {
    name: "Heineken",
    price: 250,
  },
  {
    name: "Zaječarsko pivo",
    price: 200,
  },
];
function Meni() {
  //prilozi tabularni meni
  const sideDishTabs = ["Pomfrit & Rings", "Sosevi", "Pića"];

  const [selectedTab, setSelectedTab] = useState(0);
  const [previousTab, setPreviousTab] = useState(0);
  const tabStyle = {
    transform: `translate(${selectedTab * 100}%, 0)`,
  };

  //odredjujemo sta je sa leve strane,a sta sa desne
  let leftArray = pomfritList;
  let rightArray = picaList;
  let currentArray = soseviList;
  let rightPomocniArray = null;
  let direction = selectedTab < previousTab ? "left" : "right";
  if (selectedTab == previousTab) direction = "stay";
  // console.log(direction)

  const listTabs = [pomfritList, soseviList, picaList];
  //pomeri desno
  const goRight = {
    transform: "translateX(100%)",
  };
  const goCenter = {
    transform: "translateX(0%)",
  };
  const goLeft = {
    transform: "translateX(-100%)",
  };

  //koji je burger selektovan
  const [selectedBurgerId, setSelectedBurgerId] = useState(1);

  const [selectedBurger, setSelectedBurger] = useState(burgerTypeList[0]);
  const selectBurger = (id) => {
    {
      burgerTypeList.map((element, index) => {
        if (element.id == id) {
          setSelectedBurger(element);
        }
      });
    }
    setSelectedBurgerId(id);
  };

  const sizesRef = useRef(null);
  const scrollToSizes = () => {
    if (sizesRef != null) window.scrollTo(0, sizesRef.current.offsetTop - 200);
  };
  return (
    <>
      <section className="meni-section" id="meni">
        <h1 className="meni-heading">MENI</h1>

        <h2 className="description-meni">5 tipova burgera, 5 veličina</h2>
        <div className="meni-items-container">
          <div className="burger-types-container">
            <h2 className="meni-header">1. Izaberi burger</h2>
            {burgerTypeList.map((element, index) => {
              return (
                <MeniBurgerItem
                  key={index}
                  id={element.id}
                  name={element.name}
                  ingredients={element.ingredients}
                  selectBurger={selectBurger}
                  selected={selectedBurgerId == element.id}
                  priceRange={element.priceRange}
                  scrollToSizes={scrollToSizes}
                ></MeniBurgerItem>
              );
            })}
          </div>
          <div className="sizes-container" ref={sizesRef}>
            <h2 className="meni-header">{`2. ${selectedBurger?.name} - veličine`}</h2>

            {selectedBurger?.sizes?.map((element, index) => {
              return (
                <MeniSizeItem
                  key={index}
                  name={element.name}
                  ingredients={element.ingredients}
                  price={element.price}
                ></MeniSizeItem>
              );
            })}
          </div>
        </div>
        <div className="side-dish-container">
          <h2 className="meni-header">3. Prilozi</h2>

          <div className="types-of-side-dish">
            <ul>
              {sideDishTabs.map((element, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      //ako je tab selektovan
                      if (index == selectedTab) return;

                      setPreviousTab(selectedTab);
                      setSelectedTab(index);
                    }}
                    className={`${selectedTab == index ? "selected" : ""}`}
                  >
                    {element}
                  </li>
                );
              })}
              <span className={`selected-square`} style={tabStyle}></span>
            </ul>
          </div>
          {/* LEVO */}
          <div
            className={`side-dish-items-container ${
              selectedTab == 1 ? "center" : selectedTab == 2 ? "right" : "left"
            }`}
          >
            <div className={`side-dish-single-type-container left`}>
              {leftArray?.map((element, index) => {
                return (
                  <MeniSizeItem
                    key={index}
                    name={element.name}
                    price={element.price}
                  ></MeniSizeItem>
                );
              })}
            </div>
            {/* Centar */}
            <div className={`side-dish-single-type-container center`}>
              {currentArray?.map((element, index) => {
                return (
                  <MeniSizeItem
                    key={index}
                    name={element.name}
                    price={element.price}
                  ></MeniSizeItem>
                );
              })}
            </div>
            {/* DESNO */}
            <div className={`side-dish-single-type-container right`}>
              {rightArray?.map((element, index) => {
                return (
                  <MeniSizeItem
                    key={index}
                    name={element.name}
                    price={element.price}
                  ></MeniSizeItem>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Meni;
