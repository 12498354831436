import Home from './pages/Home/Home'
import React from 'react'
function App() {
  return (
    <>
      <Home></Home>
    </>
  )
}

export default App
