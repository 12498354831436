import { useEffect, useState } from 'react'
import { ColorRing, InfinitySpin } from 'react-loader-spinner'
import SvgGradient from './SvgGradient'
import NavBar from '../../Components/NavBar'
//import jedenjeIlustracjia from '../../../public/images/jedenje-ilustracjia.png'
import { useInView } from 'react-intersection-observer'
function Landing({}) {
  useEffect(() => {
    console.log('render landing', inView)
  })

  useEffect(() => {
    const newImage = new Image()
    newImage.src = 'images/burger-compr.webp'

    // setLoaded(true)
  }, [])

  const [loaded, setLoaded] = useState(false)

  const [ref, inView, entry] = useInView({
    threshold: 0.8,
  })
  return (
    <>
      <NavBar collapsed={!inView}></NavBar>
      <section ref={ref} className='landing-section' id='landing'>
        {/* <SvgGradient></SvgGradient> */}
        <>
          <div
            className={`burger-landing-left-wrapper ${
              !inView && window.scrollY != 0 ? 'collapsed' : ''
            }`}
          >
            <img
              src='/images/burger-compr.webp'
              alt='landing burger'
              onLoad={() => {
                setLoaded(true)
              }}
            />
          </div>
          <div className='landing-content'>
            <h1 className='heading-smaller'>Najbolji</h1>
            <h1>Smash</h1>
            <h1 className='heading-smaller'>Burgeri u Beogradu</h1>
            <div className='landing-buttons'>
              <button
                className='landing-button btn-classic'
                onClick={() => {
                  // window.location('#meni')

                  var meni = document.getElementById('burger-list')

                  // meni.scrollIntoView()
                  window.scrollTo(0, meni.offsetTop - 100)
                  // window.scrollBy(0, 200)
                }}
              >
                {/* <a href='#meni'> Meni</a> */}
                Meni
              </button>
              {/* <button className='landing-button'> Poruči</button> */}
            </div>
          </div>
          {/* <img
          src='/images/jedenje-ilustracija.png'
          className='floating'
          id='jedu-ljudi'
          alt=''
        /> */}
        </>
        {!loaded ? (
          <>
            <div className='loader-container'>
              <ColorRing
                radius='1'
                colors={['#ffb527', '#ffb527', '#ffb527', '#ffb527', '#ffb527']}
                ariaLabel='loading'
              ></ColorRing>
              {/* <RotatingTriangles
              radius='1'
              color='yellow'
              ariaLabel='loading'
              colors={['yellow', 'black', 'white']}
            ></RotatingTriangles> */}
              {/* <ThreeDots
              height='80px'
              width='80px'
              radius='1'
              color='yellow'
              ariaLabel='loading'
              wrapperStyle
              wrapperClass
              className='darko'
            /> */}
              <h1 className='loader-title'>Loading</h1>
            </div>
          </>
        ) : (
          <></>
        )}
      </section>
    </>
  )
}

export default Landing
