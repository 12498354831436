import { useState } from 'react'
function NavBar({ collapsed }) {
  const [showMenu, setShowMenu] = useState(false)
  return (
    <>
      <nav className={`navbar ${collapsed ? 'collapsed' : ''}`}>
        <div className='left-nav'>
          <div
            className='logo-nav-wrapper'
            onClick={() => {
              window.scrollTo(0, 0)
            }}
          >
            <img src='/images/logo.webp' alt='logo' />
          </div>
        </div>
        <div
          className={`nav-collapsed-content ${
            showMenu ? 'show-collapsed' : ''
          }`}
        >
          <div className='right-nav'>
            <ul
              onClick={() => {
                setShowMenu(false)
              }}
            >
              <li>
                <a href='#landing'>Početna</a>
              </li>
              <li
                onClick={() => {
                  // window.location('#meni')

                  var burgerList = document.getElementById('burger-list')

                  window.scrollTo(0, burgerList.offsetTop - 100)
                }}
              >
                Burgeri
              </li>
              <li
                onClick={() => {
                  // window.location('#meni')

                  var meni = document.getElementById('meni')

                  // meni.scrollIntoView()
                  window.scrollTo(0, meni.offsetTop - 100)
                  // window.scrollBy(0, 200)
                }}
              >
                Meni
              </li>

              <li
                onClick={() => {
                  // window.location('#meni')

                  var meni = document.getElementById('contact-header')

                  // meni.scrollIntoView()
                  window.scrollTo(0, meni.offsetTop - 100)
                  // window.scrollBy(0, 200)
                }}
              >
                Kontakt
              </li>
            </ul>
          </div>
          {/* Poruci dugme, desni deo navigacije */}
          <div className='nav-poruci'>
            {/* <button className='btn-classic'>
              <a
                href='https://glovoapp.com/rs/sr/beograd/two-guys-smash-burger-beg/'
                target='_blank'
              >
                Poruči
              </a>{' '}
            </button> */}
            <div className='glovo-wrapper'>
              <a
                href='https://wolt.com/sr/srb/belgrade/restaurant/two-guys-smash-burgers'
                target={'_blank'}
              >
                {/* <p>Poručite i na Glovu!</p> */}

                <img src='/images/wolt.png' alt='glovo logo' />
              </a>
            </div>
            <div className='glovo-wrapper'>
              <a
                href='https://glovoapp.com/rs/sr/beograd/two-guys-smash-burger-beg/'
                target={'_blank'}
              >
                {/* <p>Poručite i na Glovu!</p> */}

                <img src='/images/glovo.webp' alt='glovo logo' />
              </a>
            </div>
          </div>
        </div>
        <div
          className={`nav-toggle-button`}
          onClick={() =>
            setShowMenu((showMenu) => {
              return !showMenu
            })
          }
        >
          <img src='/images/menu.webp' alt='burger-menu' />
        </div>
      </nav>
    </>
  )
}

export default NavBar
