import React from 'react'

import { PacmanLoader } from 'react-spinners'
const ContactModal = ({ setShowModal, modalHeading, modalText }) => {
  return (
    <div className='contact-modal-container'>
      <div className='modal-content'>
        <h3>{modalHeading}</h3>
        {modalText == '' ? <PacmanLoader></PacmanLoader> : <p>{modalText}</p>}

        <button
          onClick={() => {
            setShowModal(false)
          }}
        >
          Ok
        </button>
      </div>
    </div>
  )
}

export default ContactModal
