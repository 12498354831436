function Footer() {
  return (
    <>
      <footer className='footer'>
        <div className='footer-content-row'>
          <div className='logo-wrapper'>
            <img src='/images/logo.webp' alt='logo' />
          </div>

          <div className='delivery-info'>
            <div className='glovo-wrapper'>
              <a
                href='https://glovoapp.com/rs/sr/beograd/two-guys-smash-burger-beg/'
                target={'_blank'}
              >
                {/* <p>Poručite i na Glovu!</p> */}

                <img src='/images/glovo.webp' alt='glovo logo' />
              </a>
            </div>

            <div className='glovo-wrapper'>
              <a
                href='https://wolt.com/sr/srb/belgrade/restaurant/two-guys-smash-burgers'
                target={'_blank'}
              >
                {/* <p>Poručite i na Glovu!</p> */}

                <img src='/images/wolt.png' alt='glovo logo' />
              </a>
            </div>
          </div>

          <div className='social-network-container'>
            <div className='social-network-images'>
              <div className='social-wrapper'>
                <a
                  href='https://www.facebook.com/profile.php?id=100090324897885'
                  target={'_blank'}
                >
                  <img src='/images/facebook.webp' alt='facebook' />
                </a>
              </div>
              <div className='social-wrapper'>
                <a
                  href='https://www.instagram.com/two_guys.bg/'
                  target={'_blank'}
                >
                  <img src='/images/insta.webp' alt='instagram' />
                </a>
              </div>
            </div>
            {/* <p>Posetite nas i na društvenim mrežama</p> */}
          </div>
        </div>
        <div className='footer-copyright'>
          &copy;Two Guys Smash Burger, 2023
        </div>
      </footer>
    </>
  )
}

export default Footer
