import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive";
import BurgerItem from "./BurgerItem";

import { LazyLoadImage } from "react-lazy-load-image-component";
const burgerTypeList = [
  {
    id: 1,
    name: "Two Guys Smash",
    image: "/images/tg.webp",
    bestBuy: true,
    ingredients:
      "100% juneće meso, chedar sir, brioš, gorgonzola sos, suve šljive, slanina, hrskavi luk",
    keyIngredient: "suve šljive",
    keyIngredientImage: "/images/plum.webp",
    priceRange: "610-1300",
    sizes: [
      {
        name: "Single",
        ingredients: "1 x 70g juneće meso, 1 x chedar sir",
        price: 610,
      },
      {
        name: "Double",
        ingredients: "2 x 70g juneće meso, 2 x chedar sir",
        price: 790,
      },
      {
        name: "Triple",
        ingredients: "3 x 70g juneće meso, 3 x chedar sir",
        price: 900,
      },
      {
        name: "Double-Double",
        ingredients: "4 x 70g juneće meso, 4 x chedar sir",
        price: 1080,
      },
      {
        name: "Triple-Double",
        ingredients: "5 x 70g juneće meso, 5 x chedar sir",
        price: 1150,
      },
      {
        name: "Triple-Triple",
        ingredients: "6 x 70g juneće meso, 6 x chedar sir",
        price: 1300,
      },
    ],
  },
  {
    id: 2,
    name: "BBQ Bacon",
    image: "/images/bbq.webp",
    bestBuy: false,
    ingredients:
      "100% juneće meso, chedar sir, brioš, bbq sos, džem od luka, slaninica",
    keyIngredient: "džem od luka",
    keyIngredientImage: "/images/onion.webp",
    priceRange: "550-1200",
    sizes: [
      {
        name: "Single",
        ingredients: "1 x 70g juneće meso, 1 x chedar sir",
        price: 550,
      },
      {
        name: "Double",
        ingredients: "2 x 70g juneće meso, 2 x chedar sir",
        price: 740,
      },
      {
        name: "Triple",
        ingredients: "3 x 70g juneće meso, 3 x chedar sir",
        price: 870,
      },
      {
        name: "Double-Double",
        ingredients: "4 x 70g juneće meso, 4 x chedar sir",
        price: 1050,
      },
      {
        name: "Triple-Double",
        ingredients: "5 x 70g juneće meso, 5 x chedar sir",
        price: 1090,
      },
      {
        name: "Triple-Triple",
        ingredients: "6 x 70g juneće meso, 6 x chedar sir",
        price: 1200,
      },
    ],
  },
  {
    id: 3,
    name: "Jalapeño",
    image: "/images/jal.webp",
    bestBuy: false,
    ingredients:
      "100% juneće meso, chedar sir, brioš, dresing 1000 ostrva, iceberg salata, jalapeño papričice ljute",
    keyIngredient: "jalapeño paprika",
    keyIngredientImage: "/images/chili.webp",
    priceRange: "530-1190",
    sizes: [
      {
        name: "Single",
        ingredients: "1 x 70g juneće meso, 1 x chedar sir",
        price: 530,
      },
      {
        name: "Double",
        ingredients: "2 x 70g juneće meso, 2 x chedar sir",
        price: 730,
      },
      {
        name: "Triple",
        ingredients: "3 x 70g juneće meso, 3 x chedar sir",
        price: 850,
      },
      {
        name: "Double-Double",
        ingredients: "4 x 70g juneće meso, 4 x chedar sir",
        price: 990,
      },
      {
        name: "Triple-Double",
        ingredients: "5 x 70g juneće meso, 5 x chedar sir",
        price: 1080,
      },
      {
        name: "Triple-Triple",
        ingredients: "6 x 70g juneće meso, 6 x chedar sir",
        price: 1190,
      },
    ],
  },
  {
    id: 4,
    name: "Cezar",
    image: "/images/cezar.webp",
    bestBuy: false,
    ingredients:
      "100% juneće meso, chedar sir, brioš, Cezar dresing, rukola, hrskavi luk",
    keyIngredient: "rukola",
    keyIngredientImage: "/images/arugula.webp",
    priceRange: "530-1190",
    sizes: [
      {
        name: "Single",
        ingredients: "1 x 70g juneće meso, 1 x chedar sir",
        price: 530,
      },
      {
        name: "Double",
        ingredients: "2 x 70g juneće meso, 2 x chedar sir",
        price: 730,
      },
      {
        name: "Triple",
        ingredients: "3 x 70g juneće meso, 3 x chedar sir",
        price: 850,
      },
      {
        name: "Double-Double",
        ingredients: "4 x 70g juneće meso, 4 x chedar sir",
        price: 990,
      },
      {
        name: "Triple-Double",
        ingredients: "5 x 70g juneće meso, 5 x chedar sir",
        price: 1080,
      },
      {
        name: "Triple-Triple",
        ingredients: "6 x 70g juneće meso, 6 x chedar sir",
        price: 1190,
      },
    ],
  },
  {
    id: 5,
    name: "Original",
    image: "/images/og.webp",
    bestBuy: false,
    ingredients:
      "100% juneće meso, chedar sir, brioš, burger sos, kiseli krastavac, luk",
    keyIngredient: "brioš pecivo",
    keyIngredientImage: "/images/bun.webp",
    priceRange: "680-1190",
    sizes: [
      {
        name: "Single",
        ingredients: "1 x 70g juneće meso, 1 x chedar sir",
        price: 520,
      },
      {
        name: "Double",
        ingredients: "2 x 70g juneće meso, 2 x chedar sir",
        price: 720,
      },
      {
        name: "Triple",
        ingredients: "3 x 70g juneće meso, 3 x chedar sir",
        price: 840,
      },
      {
        name: "Double-Double",
        ingredients: "4 x 70g juneće meso, 4 x chedar sir",
        price: 970,
      },
      {
        name: "Triple-Double",
        ingredients: "5 x 70g juneće meso, 5 x chedar sir",
        price: 1070,
      },
      {
        name: "Triple-Triple",
        ingredients: "6 x 70g juneće meso, 6 x chedar sir",
        price: 1190,
      },
    ],
  },
];

function BurgerList({ innerRef }) {
  // const [selectedBurgerId, setSelectedBurgerId] = useState(-1)

  const SelectBurgerItem = (itemId) => {
    if (isMobile) {
      setOpenBurgersId((openBurgers) => {
        //ako je otvoren, zatvarmao ga
        if (isSelected(itemId)) {
          return openBurgersId.filter((element, index) => {
            if (element != itemId) return true;
          });
        }

        //ako dodajemo element, odnosno otvaramo dodatni akordion
        return [...openBurgers, itemId];
      });
    } else {
      setOpenBurgersId([itemId]);
    }
  };
  const isSelected = (id) => {
    for (let i of openBurgersId) {
      if (i == id) return true;
    }

    return false;
  };
  //za mobilni, vise selektovanih
  const [openBurgersId, setOpenBurgersId] = useState([]);

  // const isMobile = false

  const isMobile = useMediaQuery({ query: "(max-width:576px)" });

  const [loaded, setLoaded] = useState(false);

  const [ref, inView, entry] = useInView({
    threshold: 0.3,
  });

  useEffect(() => {
    if (!loaded) {
      if (inView) {
        setLoaded(true);
      }
    } else {
    }
  }, [inView]);
  return (
    <>
      <section
        className={`burger-list-section ${loaded ? "fade-in" : ""}`}
        ref={ref}
        id="burger-list"
      >
        <h1>Predstavljamo vam svoje burgere</h1>

        {/* Flex wrapper */}
        <div className="burger-list-section-content">
          {/* List of burgers on the left side */}
          <div className="burger-list-container">
            {/* Single row of burgers */}
            {burgerTypeList.map((burger, index) => {
              //da li je selektovan item, drugacije u zavisnosti da li je mobilni ili ne

              return (
                <BurgerItem
                  key={burger.id}
                  id={burger.id}
                  name={burger.name}
                  ingredients={burger.ingredients}
                  bestBuy={burger.bestBuy}
                  image={burger.image}
                  selected={isSelected(burger.id)}
                  SelectBurgerItem={SelectBurgerItem}
                  keyIngredient={burger.keyIngredient}
                  keyIngredientImage={burger.keyIngredientImage}
                  isMobile={isMobile}
                ></BurgerItem>
              );
            })}
          </div>
          {/* Burger on the right side which jumps into */}

          {isMobile
            ? ""
            : burgerTypeList.map((burger, index) => {
                return (
                  <div
                    key={burger.id}
                    className={`${
                      isSelected(burger.id) ? "show " : ""
                    }big-burger-right-container`}
                  >
                    <div className="burger-img-wrapper">
                      {/* <LazyLoadImage src={burger.image}></LazyLoadImage> */}
                      <img src={burger.image} alt="slika burgera" />
                    </div>
                    <div className="burger-ingredients-container">
                      <h5>Sastojci:</h5>
                      <p>{burger.ingredients}</p>
                      {/* 100% beef and other characteristic */}
                      <div className="burger-characteristic">
                        <div className="single-burger-characteristic">
                          <div className="beef-img-wrapper">
                            <img src="images/kravica.webp" alt="kravica" />
                          </div>
                          <p>100% juneće meso</p>
                        </div>
                        <div className="single-burger-characteristic">
                          <div className="beef-img-wrapper">
                            {true ? (
                              <img
                                src={burger.keyIngredientImage}
                                alt="sastojak1"
                              />
                            ) : (
                              <img
                                src={burger.keyIngredientImage}
                                alt="sastojak1"
                              />
                            )}
                          </div>
                          {true ? (
                            <p>{burger.keyIngredient}</p>
                          ) : (
                            <p>{burger.keyIngredient}</p>
                          )}
                        </div>
                      </div>

                      {/* <h6>
                        Već od <span>{burger.sizes[0].price} dinara</span>{' '}
                      </h6> */}
                    </div>
                  </div>
                );
              })}
        </div>

        <div
          className="full-menu-pointer"
          onClick={() => {
            // window.location('#meni')

            var meni = document.getElementById("meni");

            // meni.scrollIntoView()
            window.scrollTo(0, meni.offsetTop - 80);
            // window.scrollBy(0, 200)
          }}
        >
          <div className="wrapper">
            <img src="images/down-arrow.webp" alt="strelica na dole" />
          </div>
          <p>Ceo meni</p>
        </div>
      </section>
    </>
  );
}

export default BurgerList;

export { burgerTypeList };
