function ContactInfo({ imgPath, Title, text }) {
  return (
    <>
      <div className='single-contact-info'>
        {/* Slicica */}
        <div className='contact-img-wrapper'>
          <img src={imgPath} alt='kontakt slika' />
        </div>
        <div className='text-description'>
          <h5>{Title}</h5>
          {text.map((element, index) => {
            return <p key={index}>{element}</p>
          })}
        </div>
      </div>
    </>
  )
}

export default ContactInfo
