import React, { useEffect } from 'react'
import Landing from './Landing'
import Meni from '../Meni/Meni.js'
import NavBar from '../../Components/NavBar'
import Contact from '../Contact/Contact'
import BurgerList from './BurgerList'
import Footer from '../../Components/Footer'

import '../../style/css/Home/home.css'
import '../../style/css/Meni/meni.css'
import '../../style/css/Contact/contact.css'

import { useInView } from 'react-intersection-observer'
function Home() {
  useEffect(() => {
    // console.log('render - ' + inViewLanding30)
  })
  // const [refLanding20, inViewLanding20, entryLanding20] = useInView({
  //   threshold: 0.8,
  // })
  // const [refLanding30, inViewLanding30, entry2] = useInView({
  //   threshold: 0.7,
  // })
  return (
    <>
      <div className='home-page'>
        <Landing
        // refLanding20={refLanding20}
        // refLanding30={refLanding30}
        // burgerCollapsed={!inViewLanding20}
        ></Landing>
        <BurgerList></BurgerList>
        <Meni></Meni>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    </>
  )
}

export default Home
