function MeniBurgerItem({
  selected,
  name,
  ingredients,
  selectBurger,
  id,
  scrollToSizes,
  priceRange,
}) {
  return (
    <>
      <div
        className={`burger-single-item-container 
      ${selected ? 'selected' : ''}`}
        onClick={() => {
          selectBurger(id)
          scrollToSizes()
        }}
      >
        {/* <div className='burger-item-img-wrapper'>
          <img src='/images/meni-burger.png' alt='' />
        </div> */}
        <div className='burger-item-info'>
          <div className='burger-item-title menu-item-primary-text'>{name}</div>
          <div className='burger-item-ingredients menu-item-secondary-text'>
            {ingredients}
          </div>
        </div>
        {selected ? (
          <>
            <img
              src='images/play.webp'
              className='right-arrow jello'
              alt='strelica '
            />
            <span className='price-range'>{priceRange}din</span>
          </>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default MeniBurgerItem
